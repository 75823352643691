import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.spacing(0, 5, 6.25)};
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	letter-spacing: -0.6px;
	${({ theme }) => theme.breakpoints.up('md')} {
		letter-spacing: -1.6px;
	}
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.common.white};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		padding-bottom: ${({ theme }) => theme.spacing(0.5)};
	}
`
const Description = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	line-height: 1.5;
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 auto;
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		width: 70%;
	}
	letter-spacing: 0;
	.MuiTypography-root {
		font-size: inherit;
		letter-spacing: inherit;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '55px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '30px' : '0')};
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)`
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	line-height: 1;
	padding: ${({ theme }) => theme.spacing('9px', 5)};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(25)};
		padding: ${({ theme }) => theme.spacing('10.5px', 5)};
	}
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.palette.common.white};
	min-width: 170px;
	padding: ${({ theme }) => theme.spacing(1.75, 5)};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		min-width: 180px;
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
