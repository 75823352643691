import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	max-width: 100%;
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: ${({ verticalAlign }) => verticalAlign};
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	&.has-opacity {
		&:after {
			background-color: rgba(0, 0, 0, 0.4);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		align-items: flex-end;
	}
`

export { Root, OverlayContainer }
